(function ($, Drupal) {
  Drupal.behaviors.smThCore = {
    attach: function (context, settings) {
      /////////////////

      // All selects are styled with selectizejs.
      $('select:not(.shs-select)').selectize();

      // @todo move this to layout composer.
      $('.node--landing-page--full > .content').once().each(function () {
        var $this = $(this);
        var $editContentLayout = $this.find('.edit-content-layout');
        var $renderedContents = $this.find('> .field-paragraph-contents');

        if ($editContentLayout.length) {
          $renderedContents.addClass('layout-editable');

          $this.hover(
            function (e) {
              setTimeout(function () {
                $editContentLayout.addClass('opened');
              }, 200);
            },

            function (e) {
              $editContentLayout.removeClass('opened');
            }
          );


        }

      });


      // Small helper for drupal checkboxes error.
      $('.form-checkbox.error').parent().addClass('form-checkbox--error')



      /////////////////
    }
  };

})(jQuery, Drupal);